/**
 * @file global/_variables.scss
 *
 * \brief Variables
 *
 * These are universally needed files that are SCSS-specific. Most of the
 * following do not directly result in rendered CSS.
*/
$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

$loyalty: false !default;
$cr19: false !default;
$new-loyalty-popup: false !default;
$loyalty_popup_default_size: false !default;
$loyalty_top_alignment: false !default;

$productfull-bold-text: false !default;
$hide-mpp-ppu: false !default;
$clear-pay-ordering: false !default;

$findations: false !default;
$subline_as_product: false !default;

$quick-view-long-copy: false !default;

$best-shade-message: false !default;

$hide-ask-n-answer: false !default;
$tmo-order-tracking-update: false !default;

//adding for unsion
$small-m: 356px;
$small-l: 470px;
$small-max: 640px;
$medium-m: 764px;
$medium-l: 768px;
$medium-lx: 960px;
$medium-min: 641px;
$medium-mid: 812px; // Targetting from mobile portrait to mobile landscape which is (812px) and IPAD Portrait
$medium-max: 1024px;
$large-min: 1025px;
$large-med: 1240px;
$large-max: 1400px;
$xlarge-max: 1367px;
$large-xxl: 1580px;

$ab-small-down: 767px;
$ab-ipad-large-down: 736px;
$medium-ipad-p: 768px;
$ipad-header-wrapper_width: 1040px;

// Box model
$box-model: 'border-box';

// Media queries
// Media query ranges - Get ipad specific in here
// Resources: CSS Media Queries for iPads & iPhones - http://stephen.io/mediaqueries/
$small-range: (0px, $small-max);
$medium-range: ($medium-min, $medium-max);
$large-range: ($large-min, $large-max);
$xlarge-range: ($large-min, $xlarge-max);
$xlargexxl-range: ($large-xxl);

// $xlarge-range: (90.063em, 120em);
// $xxlarge-range: (120.063em);

// mobile
$mobile-portrait: max-width 375px;
$mobile-landscape: min-width 480px;

// 2019 Redesign - new breakpoints
// XX-Small (380)  0 – 379
// X-Small (540) 0 – 539
// Small (768) 540 - 799
// Medium (1024) 800 – 1039
// Large (1304) 1040 – 1559
// X-Large (1796) 1560+
$cr19-xxsmall: 380px !default;
$cr19-xsmall: 480px !default;
$cr19-small: 540px !default;
$cr19-medium: 768px !default;
$cr19-large: 1024px !default; // primary switch from mobile
$cr19-xlarge: 1280px !default;
$cr19-xxxlarge: 1940px !default;
// 2019 Redesign - typography specific breakpoints added in 2020
// X-Small = 0 – 539
// Small = 540 – 799
// Medium = 800 - 1039
// Large = 1040 - 1559
// X-Large = Browsers over 1560 wide
$cr19-typography-xsmall: 0 !default;
$cr19-typography-small: 540px !default;
$cr19-typography-medium: 800px !default;
$cr19-typography-large: 1040px !default;
$cr19-typography-xlarge: 1560px !default;

// 2019 Redesign Max widths for formatters and content containers
$cr19-max-width-xsmall: 768px !default;
$cr19-max-width-small: 1024px !default;
$cr19-max-width-medium: 1280px !default;
$cr19-max-width-large: 1440px !default;
$cr19-max-width-xlarge: 1920px !default;

// 2019 Redesign content containers outer pad vars
$cr19-pad-xxsmall: 20px !default;
$cr19-pad-xsmall: 28px !default;
$cr19-pad-small: 36px !default;
$cr19-pad-medium: 44px !default;
$cr19-pad-large: 64px !default;
$cr19-pad-xlarge: 72px !default;

$tall: 623px !default;

// Unison
$mq-sync: usn-zero '0px', usn-small-max $small-max, usn-medium-max $medium-max,
  usn-large-max $large-max;

// Typographic
$font-family-path: '/sites/clinique/themes/cl_base/fonts';

$base-font-size: 16px; // 1em = 12pt = 16px = 100%
// $base-line-height: 1.5 * $base-font-size; // change me
$base-fontawesome-font-family: 'FontAwesome', Arial, Sans-Serif !default;
$base-thin-font-family: 'HelveticaNeueLTStd35Thin', Arial, Sans-Serif !default;
$base-light-font-family: 'HelveticaNeueLTStd-UltLt', Arial, Sans-Serif !default;
$base-font-family: 'HelveticaNeueLTStd45Light', Arial, Sans-Serif !default;
$base-font-family-regular: 'HelveticaNeueLTStd45Regular', Arial, Sans-Serif !default;
$base-font-family-roman: 'HelveticaNeueLTStd55Roman', Arial, Sans-Serif !default;
$base-italic-font-family: 'HelveticaNeueLTStd56Italic', Arial, Sans-Serif !default;
$base-black-font-family: 'HelveticaNeueLTStd95Black', Arial, Sans-Serif !default;
$base-bold-font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif !default;
$base-bolder-font-family: 'HelveticaNeueLTStd75Bold', Verdana, Arial, Sans-Serif !default;
$base-link-font-family: $base-font-family !default;
$base-button-font-family: $base-bold-font-family !default;
$wink-font-family-1: 'NeuzeitOffice-Regular', HelveticaNeueLTStd55Roman, 'Segoe UI', sans-serif !default;
$wink-font-family-2: 'NeuzeitOffice-Bold', 'Helvetica Neue', 'Segoe UI', sans-serif !default;
$wink-font-family-3: Georgia, serif !default;
$zhizhi-font-family: 'Microsoft YaHei', 'Microsoft JhengHei', Arial, Sans-Serif;
$progressbar-font-family: sans-serif;
$base-pro-bolder-font-family: 'HelveticaNeueLTPro75Bold', Arial, Sans-Serif !default;
$base-pro-roman-font-family: 'HelveticaNeueLTPro55Roman', Arial, Sans-Serif !default;
$roboto-regular-font-family: 'RobotoRegular', Sans-Serif !default;
$helvetica-regular-font-family: 'CliniqueHelveticaNeueRegular', HelveticaNeue, Sans-Serif !default;
$helvetica-light-font-family: 'CliniqueHelveticaNeueLight', Sans-Serif !default;
$helvetica-bold-font-family: 'CliniqueHelveticaNeueBold', HelveticaNeueBold, Sans-Serif !default;
$helvetica-italic-font-family: 'CliniqueHelveticaNeueItalic', Sans-Serif !default;
$nitti-regular-font-family: 'NittiRegular', 'Courier New', 'ui-monospace' !default;
$nitti-medium-font-family: 'NittiMedium', 'Courier New', 'ui-monospace' !default;
$nitti-bold-font-family: 'NittiBold', 'Courier New', 'ui-monospace' !default;

// Colors
$color-white: #fff;
$color-sharp-black: #111;
$color-black: #000;
$color-gray: #bbb;
$color-grey: #aaa;
$color-gray: #bbb;
$color-lightest-grey: #e5e5e5;
$color-lightest-grey-2: #f1f1f1;
$color-lighter-grey: #e9e9ea;
$color-lightest-grey-white: #f9f9f9;
$color-light-grey: #ccc;
$color-dusty-grey: #9c9c9c;
$color-silver-grey: #cacaca;
$color-sonic-silver: #757575;
$color-dark-grey: #9d9d9d;
$color-dark-grey2: #a2a2a2;
$color-dark-grey3: #484747;
$color-light-grey-2: #999;
$color-light-grey-3: #d2d2d2;
$color-light-grey-4: #d3d3d3;
$color-greyish-white: #dcddde;
$color-darker-grey: #808080;
$color-darker-grey-2: #515254;
$color-darker-grey-3: #a1a0a0;
$color-darkest-grey: #222;
$color-darkest-grey-2: #3a3a3a;
$color-cl-grey: #d1d1d1;
$color-cl-grey-2: #d5d5d5;
$color-cl-green: #46ab8c;
$color_m_cl_green: #5db593;
$color-cl-blue: #999bdc;
$color-cl-green-2: #5dba98;
$color-cl-purple-2: #a37cc1;
$color-cl-green-2-darker: darken($color-cl-green-2, 10%);
$color-cl-soft-black: #231f20;
$color-text-black: $color-cl-soft-black;
$color-notice-pink: #ef6ea8;
$color-m-footer: #eff1f1;
$color-cl-purple: #999bdc;
$color-light-purple: #9e6f9c;
$color-grey: #e6e6e6;
$color-medium-grey: #d6d6d6;
$color-light-pink: #fde5ef;
$color-light-pink-1: #fefafb;
$color-dark-pink: #ed1e74;
$color-light-white: #f6f6f6;
$color-medium-white: #e3e4e5;
$color-c1-white: #f2f2f2;
$color-link-pink: #94298c;
$color-background-pink: #fbeff8;
$color-head-green: #53ae8c;
$color-shade-grey: #dbdbdb;
$color-red: #f00;
$color-shade-dark-grey: #999;
$color-caramin: #a50022;
$color-laser: #c79e60;
$color-alizarin: #e43228;
$color-crimson: #d1002e;
$color-cape-honey: #ffdea4;
$color-venetian-red: #b90028;
$color-monza: #d2002f;
$color-monza-light: #dd0000;
$color-cl-grey-3: #f4f4f4;
$color-cl-pink: #ffecef;
$color-cl-pink-2: #fff3e9;
$color-cl-green-3: #18bd8b;
$color-cl-green-4: #70b000;
$color-cl-green-5: #22b322;
$color-cl-grey-4: #f6f6f3;
$color-cl-or-background: #f7f7f8;
$color-cl-davy-grey: #555;
$color-cl-grey-5: #121212;
$color-cl-orange-yellow: #f4b51d;
$color-cl-light-blue: #5bafdd;
$color-facebook: #3c5a99;
$color-vkontakte: #4a76a8;
$color_celeste_approx: #cccccc;
$color-facebook-1: #3b5998;
$color-facebook-2: #5f7cbb;
$color-cl-green: #adddb7;
$color-white-1opacity: rgba(255, 255, 255, 0.1);
$color-white-17opacity: rgba(255, 255, 255, 0.17);
$color-white-2opacity: rgba(255, 255, 255, 0.2);
$color-white-4opacity: rgba(255, 255, 255, 0.4);
$color-white-6opacity: rgba(255, 255, 255, 0.6);
$color-white-75opacity: rgba(255, 255, 255, 0.75);

$color-btn-green: $color-cl-green-2;
$color-btn-green-2: #46ab8d;
$color-btn-inverse: transparent;
$color-btn-gray: #bdbcbc;
$color-btn-pink: #fd6cb4;
$color-btn-purple: #922986;
$color-btn-dark-purple: #693e84;
$color-btn-orange: #f27300;
$color-btn-hover: #bebebe;
$color-btn-dark-gray: #222;
$color-btn-blue: #4267b2;
$color-btn-blue-2: #af9ac1;

$color-outline-default: #4d90fe;

$color-btn-darker-green: $color-cl-green-2-darker;
$color-btn-darker-inverse: #f3faf7;

$color-ly-light-green: #deebd8;
$color-ly-dark-green: #cadcc2;
$color-ly-darker-green: #99bd8b;
$color-ly-shade-green: #96c7b5;

$color-ly-light-purple: #d8cfe4;
$color-ly-dark-purple: #b09dca;

$color-ly-light-orange: #faf1d7;
$color-ly-dark-orange: #fcd05a;
$color-ly-darker-orange: #eed49d;

$product-color-title: $color-cl-soft-black;
$product-color-price: #808080;

$color-blog-link: #ce1bbd;
$color-bg-grey: #fafafd;
$color-btn-voilet: #9a9cdb;
$color-cl-light-black: #1a1a1a;
$color-cl-medium-pink: #f69;
$color-cl-medium-grey: #6d6d6d;
$color-cl-dark-grey: #666;
$color-cl-darker-grey: #1f1f1f;
$color-cl-pink: #ff3b89;
$color-cl-bg-grey: #f5f1f0;
$color-btn-light-grey: #bdbdbd;
$color-btn-grey: #333;
$color-lighter-white: #f1f4f5;
$color-light-gray: #cccccc;
$color-light-cyan: #f2fffa;
$color-shimmer-grey: #edeef1;
$color-shimmer-grey-light: #f6f7f8;
$color-cl-accent: #ebf3ea;
$color-cl-light-black-2: #222222;

$color-gray-transparent: rgba(160, 160, 160, 0.15);
$color-monte-carlo: #90ccba;
$color-melanie: #dbadcc;
$color-wewak: #f69f9f;
$color-aqua-island: #94cfd7;
$color-wild-wtraberry: #ff3b89;
$color-tradewind: #5dbab1;
$color-hot-pink: #fe6cb5;
$color-dusty-gray: #989898;
$color-radical-red: #ff195e;
$color-lighter-gray: #dadada;

$color-discount-price: $color-radical-red;
//EH-ZCarousel
$color-ZCarousel-blue: #00d7cc;

//EH-3up page
$color-3up-ivory: #fffff0;
$color-3up-blue: #efffff;
$color-3up-orange: #ffbeac;
$color-3up-light-blue: #1edae7;

$color_white_ice_approx: #00d0c1;

$color-green-dark: #44a07e;
$color-green-light: #339966;
$color-grey-dark: #f5f5f5;
$color-grey-light: #eee;
$color-green-html: green;
$color-pink: #ff4661 !default;

// 2019 Redesign - new colors
$cr19-text: #1a1a1a;
$cr19-text-black: $color-black;
$cr19-text-white: $color-white;
$cr19-text-grey: #999;
$cr19-text-dark: #262626;
$cr19-text-inactive: #666;
$cr19-error: #d00;
$cr19-bg-black: $color-black;
$cr19-bg-white: $color-white;
$cr19-bg-dark: #262626;
$cr19-bg-med: #3f3f3f;
$cr19-bg-light: #fafafa;

$cr19-bg-light-transparent: rgba($cr19-bg-light, 0.5);
$cr19-bg-dark-transparent: rgba($cr19-bg-dark, 0.5);

$cr19-border-black: $color-black;
$cr19-border-dark: #3f3f3f;
$cr19-border-light: #e6e6e6;

$color-gray-dusty: #999;
$color-gray-tier1: #fcfcfc;
$color-gray-tier2: #e0e0e0;
$color-snow: #fbfbfb;
$color-nero: #212121;
$color-light-orange: #fefdfb;
$color-dark-orange: #ee9f57;
$color-light-green: #fcfdf9;
$color-dark-green: #55b300;
$color-carmine: #b00020;
$color-bg-black-transparent: rgba($color-black, 0);
$color-bg-white-transparent: rgba($color-white, 0);
$color-bg-carmine-transparent: rgba($color-carmine, 0.02);
$color-gray-tier3: #5c5c5c;
$color-med-green: #5db593;
$color-med-white: #ebebeb;
$color-mona-lisa: #ff9999;

$color-gray-tier4: #ddd;
$color-dim-gray: #707070;
$color-success-msg: #087443 !default;
$color-error-msg: #a80000 !default;
$color-alert-msg: #b54708 !default;
$color-light-blue: #ecf3eb;
$color-input-gray: #666666;
$color-progressbar-gray: #999;

// END 2019 Redesign - new colors

// Sizings
$header_height: 72px;
$layout_height: 115px;

// Gradients
// put gold gradient from styleguide pg.5  here

// Links
$link: '&:link, &:visited';
$focus: '&:focus, &:hover, &:active, &.active';
$default-underline-offset: 0.313rem;

// Compass SPRITES
//
// Sprites are imported automagically
//
// use sprites like this:
// @include sprites-sprite(
//   [$dimensions: include dimensions in output?]: true/false,
//   [$offset-x: offset from left of sprite image]: 10px,
//   [$offset-y: offset from top of sprite image]: 10px
// );
//
// Our sprite map is called $sprites-sprites

// sets the whitespace around sprites
$sprites-spacing: 25px;

// defaults to including the sprite dimensions
$sprites-sprite-dimensions: true;

// password strengthen
$password_strength: false !default;

// defaults for header image block height (used to compute other things)
$responsive-logo-height: 72px;
$responsive-logo-height-mobile: 56px;

// set per-sprite customizations here
// $sprites-down-arrow-position: 100%; // set specific sprite to right side of spritesheet
// $sprites-search-position: 100%;
// $sprites-search_hover-position: 100%;

// Misc
$img_base_path: '/sites/clinique/themes/cl_base/img';
$template_base_path: '/sites/clinique/themes/cl_base/template_api';
$libraries_base_path: '/sites/clinique/libraries';
$cc_slider_bg_gradient_color: '/media/images/SPP_Slider/cc_cream_slider_gradient.png';
// Checkout Implementation
$header1: 2.55em;
$header2: 1.8em;
$header-large-body: 1.3em;
$header-small-body: 1.05em;
$header-table-captions: 0.95em;
$header-table-header: 0.95em;
$header-medium: 1.4em;
$font-family-Roman: Helvetica Neue Roman;
$font-family-light: Helvetica Neue Light;
$font-weight-normal: normal;
$font-weight-bold: bold;
$text-transform-uppercase: uppercase;
$text-transform-lowercase: lowercase;
$text-transform-capitalize: capitalize;
$text-transform-none: none;
$lightBlack_color: #1f1f1f;
$text-decoration-underline: underline;
$font-helvetica65-medium-verdana: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
$font-helvetica65-medium-helvetica: 'HelveticaNeueLTStd65Medium', Helvetica, Arial, sans-serif;
$font-helvetica45-light: 'HelveticaNeueLTStd45Light';
//randomizer game
$color-athens-gray: #f8f9fa;
$color-porcelain: #f5f6f7;
$color-black-opacity: rgba(153, 153, 153, 0.02);
$color-black-1opacity: rgba(0, 0, 0, 0.1);
$color-black-2opacity: rgba(0, 0, 0, 0.2);
$color-black-02opacity: rgba(0, 0, 0, 0.02);
$color-black-7opacity: rgba(0, 0, 0, 0.7);
$color-black-6opacity: rgba(0, 0, 0, 0.6);
$color-black-12opacity: rgba(0, 0, 0, 0.12);
$color-black-64opacity: rgba(0, 0, 0, 0.64);
$color-black-88opacity: rgba(0, 0, 0, 0.88);
$color-black-4opacity: rgba(0, 0, 0, 0.4);
$color-black-32opacity: rgba(0, 0, 0, 0.32);
$color-black-54opacity: rgba(0, 0, 0, 0.54);
$color-black-87opacity: rgba(0, 0, 0, 0.87);
$color-sticky-footer-shadow: rgba(50, 50, 50, 0.75);
$color-monte-carlo: #83cfce;
$color-cerise-red: #e42e69;
$color-spin-pink: #f8afb8;
$color-bg-light-grey: #fdfdfd;
$color-periwinkle-blue: #9eafff;

// Pick colors
$pick_x_title-color: #000000;
$pick_cyber_title-color: #ffffff;

// Border color
$border-color: #bdbdbd !default;
//reskin colors
$color-dark-grey-bolder: #b3b0b042;
$color-border-grey: #e8e7e7;
$color-underline-grey: #aba4a4;
$color-oab-table-header: #f7f7f7;

// Simplified Star Ratings
$ratingSize: 14px;
$ratingWidth: 70px;
$pick-gift: false !default;

// Bold Badge
$badge-bold: false !default;

// Cart Cleanup
$color-cl-grey-dark: #979797;
$color-cl-grey-darker: #444;
$color-cl-medium-darker: #5f5d5d;
$font-arial: Arial, Helvetica, sans-serif;

// Track My Order.
$color-cl-green-bg: #adddb7;

// ELC BASE - SHARED Design
$main-font: 'Gotham Book', arial !default;
$color-focus: #106bfe !default;
$color-navy-blue: #0075db;
$color-forest-green: #06891e;
$color-dark-red: #cc0000 !default;
$bold-font-family: 'Gotham Bold', arial !default;
$color-dark-gray: #a2a2a2;
// Social
$color-fb-blue: #3b5898;
$color-vertical-divider: #5f7cbb;
$color-black-opacity-88: rgba($color-black, 0.88); //#000000E0;
$signin-box-lighter-gray: #d6d6d6;
$color-facebook-blue-opacity-88: rgba(59, 89, 152, 0.88);
$color-tmo-dary-grey: #303030 !default;
$color-tmo-light-grey: #efedea !default;
$color-tmo-light-green: #277e3e !default;
$color-tmo-alert-green: #eff8f1 !default;

// Gnav variables
$fixed-z-index: 9999;
$navbar-sm: 3.4375rem;
$navbar-lg: 5rem;

// Account Page specific overrides.
$regional_shared_account_signin: false !default;
$regional_shared_account_forget_password: false !default;
$regional_shared_account_profile: false !default;

// Adpl styling per locale.
$adpl_forms_styles: false !default;
$adpl_cl_forms_styles: false !default;
$adpl_co_forms_styles: false !default;
$adpl_au_forms_styles: false !default;
$adpl_cn_forms_styles: false !default;
$adpl_jp_forms_styles: false !default;
$adpl_kr_forms_styles: false !default;
$adpl_nz_forms_styles: false !default;
$adpl_th_forms_styles: false !default;
$adpl_ru_forms_styles: false !default;
$adpl_emea_forms_styles: false !default;
$adpl_at_forms_styles: false !default;
$adpl_ch_forms_styles: false !default;
$adpl_es_forms_styles: false !default;
$adpl_gr_forms_styles: false !default;
$adpl_il_forms_styles: false !default;
$adpl_pl_forms_styles: false !default;
$adpl_tr_forms_styles: false !default;
$adpl_za_forms_styles: false !default;

$chat-icon-position-right: false !default;
$newsletter-height-enrollment-popup: 100px !default;

$gnav-promo-mb-height: 42px !default;
$gnav-promo-pc-height: 36px !default;
$gnav-courtesy-height: 60px !default;
$gnav-pc-nav-height: 48px !default;
$gnav-ease-out-transition: 0.2s all ease-out !default;
